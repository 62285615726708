import styled, { css } from 'styled-components'
import BetterText from 'src/components/BetterText'
import Gap from 'lib/components/Gap'
import Separator from '@kiwicom/orbit-components/lib/Separator'
import { Grid } from '@kiwicom/orbit-components'

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 350px;
  padding: 6px 12px;
`
const RowWrapper = styled.div`
  max-width: 350px;
`

const formatValue = value => {
  if (typeof value === 'object' && value.length && value.join) {
    return value.join(', ')
  }
  if (!value && value !== 0) return '---'
  return value
}

const createColumn = data => (
  <div>
    {data.map(({ label, value }, key) => (
      <RowWrapper key={key}>
        <ListRow>
          <BetterText fontSize="14px" lineHeight="20px" fontWeight="500">
            {' '}
            {label}
          </BetterText>
          <Gap gap="16px" />
          <BetterText fontSize="14px" lineHeight="20px" textAlign="right">
            {formatValue(value)}
          </BetterText>
        </ListRow>
        <Separator />
      </RowWrapper>
    ))}
  </div>
)

const getCenter = (data, minRows) =>
  Math.min(Math.max(Math.ceil(data.length / 2), minRows), data.length)

const List = ({ data, minRows = 5 }) => {
  const centerIndex = getCenter(data, minRows)

  return (
    <Grid columns="1fr 1fr">
      {createColumn(data.slice(0, centerIndex))}
      {createColumn(data.slice(centerIndex))}
    </Grid>
  )
}

export default List
