import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import styled, { css } from 'styled-components'
import Icon from './Icon'

const disableSliderBorder = css`
 div {
    border: none !important;
    outline: none !important;
  }
`

const CardSlider = styled(Slider)`
  ${({ theme: { orbit }, hasSideDescription }) => css`
    border-radius: ${orbit.borderRadiusLarge};
    position: relative;
    ${hasSideDescription &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
  `}
`

const StyledSlide = styled(Slide)`
  ${disableSliderBorder};
`

const defaultConfig = {
  infinite: true,
  dragEnabled: false,
  touchEnabled: false,
  naturalSlideWidth: 411,
  naturalSlideHeight: 273,
}

const defaultLargeConfig = {
  ...defaultConfig,
  naturalSlideWidth: 846,
  naturalSlideHeight: 570,
}

const ButtonBase = css`
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 20px 10px;
`

const StyledButtonBack = styled(ButtonBack)`
  ${ButtonBase};
  left: 0;
`
const StyledButtonNext = styled(ButtonNext)`
  ${ButtonBase};
  right: 0;
`

const ControlButtons = ({ size, data }) =>
  data?.length > 1 ? (
    <div style={{ zIndex: 20 }}>
      <StyledButtonBack>
        <Icon.ChevronBackShadow size={size} />
      </StyledButtonBack>
      <StyledButtonNext>
        <Icon.ChevronForwardShadow size={size} />
      </StyledButtonNext>
    </div>
  ) : null

const SliderComponent = ({ data, buttonSize = 80, ...props }) => (
  <CarouselProvider
    {...defaultConfig}
    totalSlides={data.length}
    {...props}
  >
    <ControlButtons data={data} size={buttonSize} />
    <Slider>
      {data.map((component, key) =>
        <StyledSlide index={key}>{component}</StyledSlide>)}
    </Slider>
  </CarouselProvider>
)

SliderComponent.Card = ({ data, hasSideDescription, large, ...props }) => {
  const config = large ? defaultLargeConfig : defaultConfig
  return (
    <CarouselProvider {...config} totalSlides={data.length} {...props}>
      <ControlButtons data={data} size={34} />
      <CardSlider hasSideDescription={hasSideDescription}>
        {data.map((component, key) =>
          <StyledSlide index={key}>{component}</StyledSlide>)}
      </CardSlider>
    </CarouselProvider>
  )
}

export default SliderComponent
