import { Stack } from '@kiwicom/orbit-components'
import styled, { css } from 'styled-components'
import Image from 'next/image'
import Col from 'lib/components/Col'
import { Page, PageContent } from 'src/components/Page'
import Icon from './Icon'
import Slider from './Slider'
import BetterText from './BetterText'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
`

const OfferImage = styled(Image).attrs(() => ({ quality: 90 }))`
  /* height: 100%; */
`

const TestWrap = styled.div`
  width: 100%;
  height: 200px;
`

// Todo reactor - sjednotit s card komponentou

const BottomSection = styled.div`
  position: absolute;
  padding-bottom: 40px;
  bottom: 0;
  width: 100%;
  z-index: 1;
`

const Shadow = styled.div`
  ${({ theme: { orbit } }) => css`
    position: absolute;
    top: -30px;
    left: 0;
    bottom: 0;
    right: 0;
    background: black;
    z-index: -1;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  `}
`

const BottomSectionContent = styled.div`
  ${({ theme: { sizes } }) => css`
    align-items: center;
    max-width: ${sizes.pageWidth}px;
    margin: auto;
    z-index: 10;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const CardTitle = props =>
  <BetterText fontSize="28px" lineHeight="32px" fontWeight="700" color="white" {...props} />

const CardText = props =>
  <BetterText fontSize="22px" lineHeight="24px" fontWeight="400" color="white" {...props} />

const createGalleryData = gallery =>
  gallery?.map(({ filePath }) =>
    <OfferImage src={filePath} objectPosition="50% 50%" layout="fill" objectFit="cover" />)

const UserDetailTop = ({
  user,
  formattedUser,
  isMe,
  goToPrevious,
  goToNext,
  rejectApplicant,
  acceptApplicant,
}) => {
  if (!user) return null
  // const { addToFavorites, removeFromFavorites } = useFavoriteOffers()
  console.log('is me', user)
  // const { createConnection } = useCreateConnections()

  return (
    <Wrapper>
      <div style={{ position: 'relative' }}>
        <Slider
          naturalSlideWidth={1920}
          naturalSlideHeight={846}
          data={createGalleryData(user.gallery)}
        />
      </div>

      <BottomSection>
        <Shadow />
        <BottomSectionContent>
          <Col>
            <CardTitle>{formattedUser.name}</CardTitle>
            <CardText>{formattedUser.topTitle}</CardText>
          </Col>
          <Col>
            {!isMe && (
              <Stack direction="row" align="center">
                <Icon.Back onClick={goToPrevious} />
                <Icon.No onClick={rejectApplicant} />
                <Icon.Yes onClick={acceptApplicant} />
                <Icon.StarLike />
                <Icon.Next onClick={goToNext} />
              </Stack>
            )}
          </Col>
          {/* <Col alignItems="flex-end">
            <CardTitle color="blueLight">{formattedOffer.price}</CardTitle>
            {formattedOffer.monthlyFees &&
              <CardText color="blueLight">+ {formattedOffer.monthlyFees}</CardText>}
          </Col> */}
        </BottomSectionContent>
      </BottomSection>
    </Wrapper>
  )
}

export default UserDetailTop
