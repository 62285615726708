import userModel from 'models/user'
import videoModel from 'models/video'
import { FieldTypes } from 'lib/models/safe'
import { format as formatDate, isPast as isDateInPast, parseISO, getYear } from 'date-fns'

const MODELS = {
  user: userModel,
  video: videoModel,
}

export const getCreateList = (formattedData, modelName) => fields =>
  fields
    .map(fieldKey => {
      if (typeof fieldKey === 'object') return fieldKey
      const model = MODELS[modelName]
      const fieldConf = model.fields[fieldKey]

      const label = fieldConf.listLabel || fieldConf.label
      const value = formattedData[fieldKey]
      if (!value && value !== 0) return null
      return {
        label,
        value,
      }
    })
    .filter(item => !!item)

const formatePrice = (price, unit) =>
  typeof price !== 'undefined' ? `${price.toLocaleString('cs-CZ')} ${unit}` : ''

const getLabel = (value, options, field = 'label') =>
  options.find(option => option.value === value)?.[field]

const defaultDateFormat = 'd. M. yyyy'

const baseFormate = (data, model) => {
  const formattedData = { ...data }
  if (!data) return null
  Object.keys(data).forEach(key => {
    const fieldConfig = model.fields[key]
    const value = data[key]

    if (!fieldConfig) return
    const { format, getUnit, type, options } = fieldConfig
    let { unit } = fieldConfig
    if (getUnit) unit = getUnit(data)

    if (format === 'PRICE') {
      // todo proměnnou z konfigu
      formattedData[key] = formatePrice(value, unit)
    } else if (format === 'SURFACE') {
      formattedData[key] = `${value} ${unit}`
    } else if (format === 'DATE') {
      formattedData[key] = formatDate(parseISO(value), defaultDateFormat)
    } else if (type === FieldTypes.TextArray) {
      // namapovat hodnoty na labely
      formattedData[key] = value?.length !== 0 ? value.map(v => getLabel(v, options)) : null
    } else if (type === FieldTypes.Select) {
      formattedData[key] = getLabel(value, options)
    } else if (type === FieldTypes.Date) {
      formattedData[key] = formatDate(parseISO(value), defaultDateFormat)
    } else if (type === FieldTypes.Boolean) {
      formattedData[key] = value === true ? 'Ano' : value === false ? 'Ne' : ''
    }
  })
  return { ...formattedData, original: data }
}

// const formatOffer = data => {
//   if (!data) return null
//   const formattedData = baseFormate(data, offerModel)

//   // custom fields
//   // const propertyLabe = propertyTypeOptions.find(option => option.value === data.propertyType).label
//   const propertyLabel = getLabel(data.propertyType, propertyTypeOptions)
//   const offerTypeLabel = getLabel(data.offerType, offerTypeOptions)
//   const propertyLabel2 = getLabel(data.propertyType, propertyTypeOptions, 'label2')
//   formattedData.cardTitle = `${propertyLabel} ${data.disposition}, ${data.floorArea} m²`
//   // const mainTitle = data.offerType === 'sale' ? ''
//   formattedData.mainTitle = `${offerTypeLabel} ${propertyLabel2} ${data.disposition}, ${data.floorArea} m²`

//   // todo - dořešit zobrazení adresy.
//   formattedData.addressTitle = `${formattedData.city || data.city}, okres ${formattedData.district}`

//   if (formattedData.city === 'Praha') {
//     formattedData.addressTitle = `${data.street}, ${formattedData.pragueQuarter} - ${formattedData.cityPart}`
//   }

//   formattedData.shortedUtilities = formattedData.utilities
//   if (formattedData.floorNumber) {
//     formattedData.floorNumber += '.'
//   }

//   if (data.numberOfReservedParkingSpaces > 0) {
//     formattedData.utilities.push('Parkovací místo')
//   }

//   formattedData.htmlDescription = formattedData.description && formattedData.description.replace(/(\r\n|\n|\r)/gm, '<span> </span>') // todo
//   // todo - toto zatím není potřeba, protože není moc možností co tam vyplnit
//   // formattedData.shortedUtilities =
//   //   formattedData.utilities.length > 5
//   //     ? [...formattedData.utilities.slice(0, 4), '...']
//   //     : formattedData.utilities

//   if (isDateInPast(parseISO(data.availableFrom))) {
//     formattedData.availableFrom = 'ihned'
//   }
//   formattedData.cardPrice = formatePrice(data.price, 'Kč')
//   formattedData.cardFees = formatePrice(data.monthlyFees, 'Kč')
//   // if (formattedData.advertPriceCommissionType === 'Není relevantní') {

//   // }

//   return formattedData
// }

const formatUser = data => {
  if (!data) return null
  const formattedData = baseFormate(data, userModel)
  // if (data.birthYear) {
  //   const currentYear = getYear(new Date())
  //   formattedData.age = currentYear - data.birthYear
  // }
  // const { age } = formattedData
  // formattedData.topTitle = `${data.job}`
  // if (formattedData.topTitle) {
  //   if (age) {
  //     formattedData.topTitle += `, ${age} let`
  //   } else {
  //     formattedData.topTitle += `${age}let`
  //   }
  // }

  return formattedData
}
const formatVideo = data => {
  if (!data) return null
  const formattedData = baseFormate(data, videoModel)
  return formattedData
}

const dataFormatter = (data, model) => {
  if (model === 'user') {
    return formatUser(data)
  }
  if (model === 'video') {
    return formatVideo(data)
  }
  return baseFormate(data)
}

export default dataFormatter

export const formatCollection = (collection = [], model) =>
  collection.map(c => dataFormatter(c, model))
