import styled, { css } from 'styled-components'
import { Stack, Text, Heading } from '@kiwicom/orbit-components'
import Row from 'lib/components/Row'
import Icon from 'src/components/Icon'

const WrapperBox = styled.div`
  ${({ theme: { orbit }, isProfile }) => css`
    /* box-shadow: ${orbit.boxShadowAction}; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #bac7d5;
    padding: 24px 24px 24px 24px;
    border-radius: ${orbit.borderRadiusLarge};

    ${isProfile &&
    css`
      box-shadow: ${orbit.boxShadowAction};
      background: white;
      border: none;
    `}/* img {
      max-width: 100%;
      max-height: 156px;
      margin: auto;
    } */
  `}
`

// todo duplikace z Icons.js
const Circle = styled.div`
  ${({ color, size, opacity = 0.65, pointer }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${color};
    width: ${size}px;
    height: ${size}px;
    opacity: ${opacity};
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.3); // todo - nepoužít z orbitu?
    cursor: ${pointer ? 'pointer' : 'inherit'};
  `}
`

const ShieldWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  border-radius: 50%;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.3); // todo - nepoužít z orbitu?
  width: 94px;
  height: 94px;

  img {
    width: 54px;
    height: 54px;
  }
`
const ShieldContent = styled.div`
  ${({ color }) => css`
    position: absolute;
    font-size: 25px;
    left: 50%;
    transform: translate(-45%, -60%);
    top: 50%;
    color: ${color};
    span {
      font-size: 12px;
    }
  `}
`

const Shield = ({ percent }) => {
  const shieldImage =
    percent > 50 ? '/images/icons/shield-green.svg' : '/images/icons/shield-orange.svg'
  const color = percent > 50 ? '#27AE60' : '#ff9a3d'
  return (
    <ShieldWrapper>
      <img src={shieldImage} alt="" />
      <ShieldContent color={color}>
        {percent}<span>%</span>
      </ShieldContent>
    </ShieldWrapper>
  )
}

const ICON_SIZE = 18

const FullCheck = () => <Icon.CheckMark color="#27AE60" size={ICON_SIZE} />
const PartCheck = () => <Icon.CheckMark color="#FF9A3D" size={ICON_SIZE} />
const NoCheck = () => <Icon.Close color="#F95159" size={ICON_SIZE} />

// Ověřený telefon - 10 %
// Fotografie - 10 až 25 %
// Osobní představení - 20 %
// Potvrzené zaměstnání - 15 %
// Spolehlivý plátce - 15 %
// Bezdlužnost - 10 %
// Reference - 5 %

const VerificationStatus = ({ isProfile, percent = 80 }) => (
  <WrapperBox isProfile={{ isProfile }}>
    <Stack spacing="small">
      {isProfile && (
        <Stack align="center" justify="center">
          <Heading align="center" type="title2">
            Důvěryhodnost
          </Heading>
        </Stack>
      )}
      <Stack align="center" justify="center">
        <Shield percent={percent} />
      </Stack>
      <Stack align="center" spacing="small">
        <FullCheck />
        <Text>Ověřený telefon</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <PartCheck />
        <Text>Fotografie</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <FullCheck />
        <Text>Osobní představení</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <FullCheck />
        <Text>Potvrzené zaměstnání</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <FullCheck />
        <Text>Spolehlivý plátce</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <FullCheck />
        <Text>Bezdlužnost</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <NoCheck />
        <Text>Reference</Text>
      </Stack>
    </Stack>
  </WrapperBox>
)
const VerificationStatus2 = ({ isProfile, percent = 60 }) => (
  <WrapperBox isProfile={{ isProfile }}>
    <Stack spacing="small">
      {isProfile && (
        <Stack align="center" justify="center">
          <Heading align="center" type="title2">
            Důvěryhodnost
          </Heading>
        </Stack>
      )}
      <Stack align="center" justify="center">
        <Shield percent={percent} />
      </Stack>
      <Stack align="center" spacing="small">
        <Icon.CheckMark color="#27AE60" size={ICON_SIZE} />
        <Text>ověřený telefon</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <Icon.CheckMark color="#FF9A3D" size={ICON_SIZE} />
        <Text>fotografie</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <Icon.Close color="#F95159" size={ICON_SIZE} />
        <Text>doplňující informace</Text>
      </Stack>
      <Stack align="center" spacing="small">
        <Icon.Close color="#F95159" size={ICON_SIZE} />
        <Text>ověřená bezdlužnost</Text>
      </Stack>
    </Stack>
  </WrapperBox>
)

export default VerificationStatus
