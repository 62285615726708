// import { createBasicServerSideProps, return404, withSkipRevisit } from 'lib/next'
import userMode from 'models/user'
// import { useEffect, useState } from 'react'
import { Page, PageContent } from 'src/components/Page'

// import {
//   useOffersQuery,
//   prefetchOffers,
//   useOfferQuery,
//   useCreateOfferMutation,
//   useUpdateOfferMutation,
//   prefetchOffer,
//   useDeleteOfferMutation,
// } from 'src/queries/offers'

// import { Field, Form, Formik } from 'formik'

import Button from '@kiwicom/orbit-components/lib/Button'
import Card from '@kiwicom/orbit-components/lib/Card'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Text from '@kiwicom/orbit-components/lib/Text'
import Inline from '@kiwicom/orbit-components/lib/Inline'
import { Grid } from '@kiwicom/orbit-components'
import ModelField from 'src/components/fields/ModelField'
import ModelFormik from 'src/components/fields/ModelFormik'
// import Alert from 'src/components/Alert'
import UserDetailTop from 'src/components/UserDetailTop'
import List from 'src/components/List'
import Gap from 'lib/components/Gap'
import Col from 'lib/components/Col'
import VerificationStatus from 'src/components/VerificationStatus'
// import { useRouter } from 'next/router'
// import keystone from 'keystone'
// import BetterLink from 'src/components/BetterLink'
// import { links } from 'src/links'
// import { Dialog } from '@kiwicom/orbit-components'
import dataFormatter, { getCreateList } from 'src/utils/dataFormatter'
import { useRouter } from 'next/router'
import { links } from 'src/links'

const UserDetail = ({ user, isMe, goToNext, goToPrevious, rejectApplicant, acceptApplicant }) => {
  const router = useRouter()

  if (!user) return null

  //   const {
  //     query: { slug },
  //   } = useRouter()

  // const { data: offer, isSuccess } = useOfferQuery(slug)
  //   // todo - dělat nějak automaticky při stažení?
  const formattedUser = dataFormatter(user, 'user')
  // console.log('user', user)
  // console.log('formattedUser', formattedUser)
  //   console.log('Formated offer', formattedOffer)

  //   const deleteMutation = useDeleteOfferMutation({ params: { slug } })

  //   const [showDialog, setDialog] = useState(false)
  //   const router = useRouter()

  let animalsValue = formattedUser.hasAnimals
  if (animalsValue === 'Ano' && formattedUser.animals) animalsValue += `, ${formattedUser.animals}`

  const createList = getCreateList(formattedUser, 'user')
  return (
    <div>
      {/* <pre>{JSON.stringify(offer, null, 2)}</pre> */}
      {/* TODO: zde muze byt nejaky loading stav */}
      <UserDetailTop
        user={user}
        formattedUser={formattedUser}
        isMe={isMe}
        goToNext={goToNext}
        goToPrevious={goToPrevious}
        acceptApplicant={acceptApplicant}
        rejectApplicant={rejectApplicant}
      />
      <Page bg="cloudLight">
        <Gap gap="56px" />

        <PageContent align="center">
          <Grid columns="minmax(0,716px) 276px" gap="32px">
            <div>
              <Stack spacing="large">
                <div>
                  <Heading type="title2">Představení zájemce</Heading>
                  <Gap gap="16px" />
                  <Text>{formattedUser.description}</Text>
                </div>
                <div>
                  <Heading type="title2">Informace o zájemci</Heading>
                  <Gap gap="10px" />
                  
                </div>
              </Stack>
            </div>
            <div>
              <Stack align="center" direction="column">
                <VerificationStatus isProfile />
                <div style={{ margin: 'auto' }}>
                  <Button
                    disabled={isMe}
                    onClick={() => {
                      router.push({
                        pathname: links.MESSAGES,
                        query: { n: user.nanoid, c: 'NEW' },
                      })
                    }}
                  >
                    Kontaktovat uživatele
                  </Button>
                </div>
              </Stack>
            </div>
          </Grid>
        </PageContent>
        <Gap gap="112px" />
      </Page>
    </div>
  )
}

export default UserDetail
